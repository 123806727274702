<template>
  <el-input v-model="internalValue" clearable @input.native="$emit('input', $event.target.value)">
    <GetSmsVerificationCode slot="append" :phone-number="value" :types-of="typesOf"></GetSmsVerificationCode>
  </el-input>
</template>

<script>
import GetSmsVerificationCode from './GetSmsVerificationCode.vue'

export default {
  name: 'PhoneVerificationCode',
  props: {
    value: {
      type: String
    },
    typesOf:{
      type: String
    }
  },
  components: {
    GetSmsVerificationCode
  },
  watch: {
    value() {
      this.internalValue = this.value
    }
  },
  data() {
    return {
      internalValue: ''
    }
  }
}
</script>
<template>
<el-popover trigger="click" placement="bottom" ref="popover">
  <el-button slot="reference" class="get-sms-code-button" :disabled="isSendingCode || !isValidPhoneNumber(phoneNumber)">
    <span v-if="!isSendingCode">获取验证码</span>
    <span v-else-if="timeout > 0">请{{ timeout }}秒后再获取验证码</span>
    <span v-else>正在发送验证码</span>
  </el-button>
  <div class="code-image-loader">
    <ValidationCodeImage ref="codeImgForm" v-model="code" @uuid-updated="uuid = $event" @commit-form="sendCode"></ValidationCodeImage>
    <el-button size="small" type="primary" @click="sendCode">发送短信</el-button>
  </div>
</el-popover>
</template>

<script>
import { isValidPhoneNumber } from '@@/utils/validation'
import { verificationCode } from '@@/api/login'
import ValidationCodeImage from './ValidationCodeImage.vue'

export default {
  name: 'GetSmsVerificationCode',
  props: {
    phoneNumber: {
      type: String,
      required: true
    },
    typesOf:{
      type: String,
    }
  },
  components: {
    ValidationCodeImage
  },
  data() {
    return {
      isSendingCode: false,
      timeout: 0,
      timeoutHandle: null,
      code: '',
      uuid: ''
    }
  },
  methods: {
    async sendCode() {
      const me = this
      if (!isValidPhoneNumber(this.phoneNumber)) {
        me.$message.error("手机号格式不正确!")
        return
      }
      me.isSendingCode = true
      let tout
      try {
        tout = await verificationCode({phone: me.phoneNumber, code:me.code, uuid: me.uuid,type:this.typesOf})
      } catch(e) {
        me.isSendingCode = false
        console.error(e)
        return
      } finally {
        me.code = ''
        me.uuid = ''
        me.$refs.codeImgForm.getCode()
      }
      const dt = Math.round(new Date().getTime() / 1000 + parseInt(tout.data))
      const key = `validation-code-timeout-${me.phoneNumber}`
      function clearCodeTimeout() {
        if (me.timeoutHandle) {
          clearTimeout(me.timeoutHandle)
        }
        sessionStorage.removeItem(key)
      }
      clearCodeTimeout()
      sessionStorage.setItem(key, '' + dt)
      function updateTimeout() {
        const ndt = Math.round(parseInt(sessionStorage.getItem(key)))
        if (!ndt || ndt <= 0) {
          me.isSendingCode = false
          return clearCodeTimeout()
        }
        me.timeout = Math.round(ndt - new Date().getTime() / 1000)
        if (me.timeout <= 0) {
          me.isSendingCode = false
          return clearCodeTimeout()
        }
        timeItOut()
      }
      function timeItOut() {
        me.timeoutHandle = setTimeout(updateTimeout, 1000)
      }
      timeItOut()
      this.$refs.popover.doClose()
    },
    isValidPhoneNumber
  }
}
</script>
<template>
  <div class="content">
    <div class="top">
      <ul class="third">
          <li>帮助中心</li>
          <li v-for="(item,index) in navListHelp" :key="index" @click="skip(index,'help')">{{item.title}}</li>
      </ul>
      <ul class="fourth">
          <li>关于我们</li>
          <li v-for="(item,index) in navListAbout" :key="index" @click="skip(index,'about')">{{item.title}}</li>
      </ul>
      <ul class="fifth">
          <li>联系我们</li>
          <li>联系电话：400-1166-160</li>
          <li>邮箱：zczckjfzyxgs@163.com</li>
          <li>地址：石家庄新华区荣鼎天下</li>
      </ul>

    </div>
    <hr />
      <div class="text">
        <span>版权声明&nbsp;&nbsp;&nbsp;&nbsp;  </span>
        <span><el-link href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration: underline"> 冀ICP备2022024858号 </el-link></span>
        <span><el-link href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=13010502002114" target="_blank"
                       style="text-decoration: underline; padding-left: 20px;">
          <img src="https://www.gzzczb.com/m/test/static/images/gwab.png" style="float: left;padding-right: 5px;">
           冀公网安备 13010502002114号 </el-link>
        </span>
        <span> &nbsp;&nbsp;&nbsp;&nbsp;北京中财智采科技发展有限公司河北分公司 </span>
      </div>
  </div>
</template>

<script>

export default {
  components: {

  },
  data() {
    return {
      navListHelp:[{title:'CA相关'},{title:'专家指南'},{title:'投标指南'}],
      navListAbout:[{title:'平台介绍'},{title:'联系我们'}],
    }
  },
  methods:{
    skip(activeName,type) {
      if (type=='help') {
        this.$router.push({
          path: "/help",
          query: {activeName: activeName },
        });
        this.$bus.$emit("changeMainNav", "/help");
      } else if(type=='about'){
        this.$router.push({
          path: "/ourself",
          query: {activeName: activeName },
        });
        this.$bus.$emit("changeMainNav", "/ourself");
      }


    },
  }
}
</script>
<style scoped lang="less">
a{
  text-decoration: none;
  color: white;
}
.content .top ul{
  text-align: left;
  margin-top: 40px;
  font-size: 12px;
}
.content{
  background-color: #0F0F0F;
  width: 100%;
  height: 300px;
  // margin-top: 25px;
  color: #FFFFFF;
}
.top{
  width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}
.top ul li{
  color: #999999;
  margin-bottom: 15px;
  &:nth-of-type(1){
    font-size: 16px;
    color:#FFFFFF;
  }
}
.top .first{
  margin-left: 100px;

}
hr{
  background: -webkit-linear-gradient(left, #000000,#d7d7d7 , #ffffff,#d7d7d7,#000000 ); /* Safari 5.1 - 6.0 */
  background: -o-linear-gradient(right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* Opera 11.1 - 12.0 */
  background: -moz-linear-gradient(right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* Firefox 3.6 - 15 */
  background: linear-gradient(to right, #000000 ,#d7d7d7, #ffffff,#d7d7d7,#000000 ); /* 标准的语法 */
  border:none;
  height: 1px;
  opacity: 1;
}
.text{
  text-align: center;
  font-size: 14px;
  color: #999999;
  padding-top: 20px;
}
.text a{
  color: #FFFFFF;
}
hr{
  margin-top: 60px;
}
</style>

import request from '@@/utils/request'
import { encryptPublicLong } from '@lsqswl/rsaencrypt'

const key = {
  base64: '',
  expires: 0
}
const localStoreKey = 'zck'

function loadKey() {
  const str = sessionStorage.getItem(localStoreKey)
  if (str) {
    try {
      Object.assign(key, JSON.parse(str))
    } catch {}
  }
}

loadKey()

function now() {
  return Math.round(new Date().getTime() / 1000)
}

// 登录方法 enterpriseName, userName, password, code, uuid
export async function login({uuid, code, enterpriseName, userName, password}) {
  if (!key.base64 || !key.expires || key.expires < now()) {
    const got = await request({
      url: '/zck', method: 'get', headers: {
        isToken: false
      }
    })
    const data = got.data
    key.base64 = data.base64
    key.expires = now() + data.expires
    sessionStorage.setItem(localStoreKey, JSON.stringify(key))
  }
  const data = {
    uuid, code,
    zcd: encryptPublicLong(JSON.stringify({enterpriseName, userName, password}), key.base64)
  }
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data,
  })
}

export function getCALoginCode() {
  return request({
    url: '/hebca/randomString',
    method: 'get',
    headers: {
      isToken: false
    }
  })
}

export function loginWithCA({enterpriseName, signedData, certB64, uuid, caEnterpriseName}) {
  return request({
    url: '/hebca/login',
    method: 'post',
    headers: {
      isToken: false
    },
    data: { enterpriseName, signedData, certB64, uuid, caEnterpriseName }
  })
}

export function loginBindToCA({signedData, certB64}) {
  return request({
    url: '/hebca/bind',
    method: 'post',
    headers: {
      isToken: false
    },
    data: { signedData, certB64 }
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/auth/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data,
  })
}

// 刷新方法
export function refreshToken() {
  return request({
    url: '/auth/refresh',
    headers: {
      isToken: false
    },
    method: 'post'
  })
}

// 获取用户详细信息
export function getUserInfo() {
  return request({
    url: '/system/user/getInfo',
    headers: {
      isToken: false
    },
    method: 'get'
  })
}

// 获取企业信息
export function getEnterpriseInfo() {
  return request({
    url: '/system/enterprise/getInfo',
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    headers: {
      isToken: false
    },
    method: 'delete'
  })
}

// 获取验证码
export function getCodeImg(forced) {
  return request({
    url: '/code' + (forced ? "?f=t" : ''),
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

//获取手机验证码
export function verificationCode({phone, code, uuid,type}) {
  return request({
    url: '/system/login/verificationCode',
    method: 'post',
    headers: {
      isToken: false
    },
    params: {phone,type},
    data: {code, uuid}
  })
}
/** 查询树型字典列表根据父节点查询数据 - 一次性加载 */
export function listall(query) {
  return request({
      url: '/system/treedict/listall',
      method: 'get',
      headers: {
        isToken: false
      },
      params: query
  })
}
/** 新增招投标主体信息 */
export function addInfoApi(data) {
  return request({
      url: '/zb/info',
      method: 'post',
      headers: {
        isToken: false
      },
      data: data
  })
}
/** 修改招投标主体信息 */
export function editInfoApi(data) {
  return request({
      url: '/zb/info',
      method: 'put',
      headers: {
        isToken: false
      },
      data: data
  })
}
/** 查询招投标主体信息列表 */
export function listInfoApi(query) {
  return request({
      url: '/zb/info/list',
      method: 'get',
      headers: {
        isToken: false
      },
      params: query
  })
}
/** 信用中国，企业名称模糊查询，返回企业列表 */
export function creditChinaSearch(query) {
  return request({
      url: '/search/searchcreditchina/creditchina/search',
      method: 'get',
      headers: {
        isToken: false
      },
      params: query
  })
}
/** 信用中国，企业名称模糊查询，返回企业列表 */
export function getDist(query) {
  return request({
      url: '/system/dict/data/type/zc_economic_type',
      method: 'get',
      headers: {
        isToken: false
      },
      params: query
  })
}

/** 查询主体信息变更申请详细 */
export function getApplyApi(id) {
  return request({
      url: '/zb/apply/' + id,
      headers: {
        isToken: false
      },
      method: 'get'
  })
}

/** 新增主体信息变更申请 */
export function addApplyApi(data) {
  return request({
      url: '/zb/apply',
      method: 'post',
      headers: {
        isToken: false
      },
      data: data
  })
}

/** 修改主体信息变更申请 */
export function editApplyApi(data) {
  return request({
      url: '/zb/apply',
      method: 'put',
      headers: {
        isToken: false
      },
      data: data
  })
}


/** 查询投标文件列表 */
export function listByGuidsStr(guidsStr) {
  return request({
      url: '/file/attachment/listByGuidsStr/'+guidsStr,
      headers: {
        isToken: false
      },
      method: 'get'
  })
}

/** 查询招投标主体信息列表 */
export function infoTbApi() {
    return request({
        url: '/tb/infotb/list',
        headers: {
          isToken: false
        },
        method: 'get',
    })
}

/** 查询招投标主体信息列表 */
export function infoZbApi() {
    return request({
        url: '/zb/info/list',
        // headers: {
        //   isToken: false
        // },
        method: 'get',
    })
}

//手机验证码登录
export function verificationCodeLogin(data) {
  return request({
    url: '/system/login/verificationCodeLogin',
    // headers: {
    //   isToken: false
    // },
    method: 'post',
    data
  })
}
/** 手机号重置密码 */
export function verificationCodeResetPwdApi(data) {
    return request({
        url: '/system/login/verificationCodeResetPwd',
        headers: {
            isToken: false
        },
        method: 'post',
        data: data
    })
}
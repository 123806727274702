import request from '@@/utils/request'
let serverTimeDiff = null

setInterval(() => serverTimeDiff = null, 60*1000)

/** 服务器时间 */
export async function ServerTimeApi(params) {
  if (serverTimeDiff == null) {
    const {data} = await request({
      url: '/yw/zbgg/getNowTime',
      method: 'get',
      headers: {
        isToken: false
      },
      params: params
    })
    serverTimeDiff = data - new Date().getTime()
  }
  return {
    code: 200,
    data: new Date().getTime() + serverTimeDiff
  }
}

// 首页交易信息列表
export function getTransactionList(params) {
    return request({
      url: '/zb/zbgg/zhu/list?ggAnnouncement='+params,
      method: 'get',
      headers: {
        isToken: false
      },
      data: params
    })
  }
  // 注册 /login/register
export function uregister(params) {
  return request({
    url: '/system/login/register',
    method: 'post',
    headers: {
      isToken: false
    },
    data: params
  })
}

/** 注册代理机构 */
export function registerAgency(params) {
  return request({
    url: '/yw/registration/Agency',
    method: 'post',
    headers: {
      isToken: false
    },
    data: params
  })
}

/** 注册招标人/业主 */
export function registerTenderer(params) {
  return request({
    url: '/yw/registration/Tenderer',
    method: 'post',
    headers: {
      isToken: false
    },
    data: params
  })
}

//查询轮播图图片
export function lbimages(data) {
  return request({
    url: '/yw/chart/list',
    method: 'get',
    headers: {
      isToken: false
    },
    data
  })
}
//查询交易页面数据
export function getZbggType(data) {
  return request({
    url: '/yw/zbgg/getZbggType',
    method: 'post',
    headers: {
      isToken: false
    },
    data
  })
}

//查询交易页面数据
export function getGgList(params) {
  return request({
    url: '/yw/zbgg/getGgList',
    method: 'get',
    headers: {
      isToken: false
    },
    params: params
  })
}

//查询帮助中心
export function helplist(data) {
  return request({
    url: '/yw/helpcenter/list?helpType='+data,
    headers: {
      isToken: false
    },
    method: 'get',
  })
}

//查询新闻公告
export function xinwenlist(params) {
  return request({
      url: '/yw/industryInformation/list',
      method: 'get',
      headers: {
        isToken: false
      },
      params: params
  })
}
//新闻详情
export function getIndustryInformationApi(id) {
  return request({
      url: '/yw/industryInformation/' + id,
      headers: {
        isToken: false
      },
      method: 'get'
  })
}
//查询工具栏
export function toolslist(params) {
  return request({
    url: '/yw/tools/list',
    method: 'get',
    headers: {
      isToken: false
    },
    data: params
  })
}

//查询公告详情
export function getOneGg(data) {
  return request({
    url: `/yw/zbgg/getOneGg`,
    method: 'post',
    headers: {
      isToken: false
    },
    data
  })
}
//查询原公告
export function getOriginalGgApi(params) {
  return request({
    url: `/yw/zbgg/getOriginalGg`,
    method: 'get',
    headers: {
      isToken: false
    },
    params:params
  })
}
//帮助中心详情
export function getHelpcenterApi(id) {
  return request({
      url: '/yw/helpcenter/' + id,
      headers: {
        isToken: false
      },
      method: 'get'
  })
}
/**
 * 仅用于地区编码查询，仅查询省市两级
 */
export async function listTwoAll(query) {
  return request({
    url: '/system/treedict/listTwoAll',
    method: 'get',
    headers: {
      isToken: false
    },
    params: query
  })
}
/** 根据地区id查询一个配置 */
export function getByAreaIdApi(query) {
  return request({
    url: '/yw/regionManagement/getByAreaId',
    method: 'get',
    headers: {
      isToken: false
    },
    params: query
  })
}
/** 专家入库 **/
export function expertStorageApi(data) {
  return request({
    url: '/yw/userdetail/expertStorage',
    method: 'post',
    headers: {
      isToken: false
    },
    data
  })
}
// 性别
export function getDict(query) {
  return request({
    url: `/system/dict/data/type1/${query}`,
    method: 'get',
    headers: {
      isToken: false
    },
  })
}
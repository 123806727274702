<template>
  <div class="app container-md">
    <div class="NavHeader">
      <NavHeader  />
    </div>
    <div class="app_content">
      <router-view></router-view>
      <right-menu></right-menu>
      <NavFooter />
    </div>
  </div>
</template>

<script>
import NavHeader from "@@/components/NavHeader.vue";
import NavFooter from "@@/components/NavFooter.vue";
import rightMenu from "@@/components/rightMenu.vue";

export default {
  components: {
    NavHeader,
    NavFooter,
    rightMenu,
  },
  created(){
  },
  watch:{
    '$route':{
        immediate:true,
        handler(n){
            if(n.path=='/'){
                this.$router.push('/home')
            }
            sessionStorage.setItem('menu',n.path)
        }
    }
  }
};
</script>
<style scoped>
.app{
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 1360px;
}
.NavHeader{
  height: 100px;
}
.app_content{
  //min-height: 500px;
  height: calc(100% - 100px);
  overflow: hidden;
  overflow-y: auto;
}
/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: #f5f5f5;
}
/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
<template>
  <div class="captcha">
    <el-input class="code" prefix-icon="el-icon-warning-outline" placeholder="验证码" @keyup.enter.native="$emit('commit-form')"
      v-model.trim="code" clearable @input.native="$emit('input', $event.target.value)"></el-input>
    <img :src="codeUrl" @click="getCode" class="code-img"/>
  </div>
</template>

<script>
import { getCodeImg } from '@@/api/login'

export default {
  name: 'ValidationCodeImage',
  props: {
    value: {
      type: String
    }
  },
  data() {
    return {
      code: '',
      codeUrl: ''
    }
  },
  watch: {
    value() {
      this.code = this.value
    }
  },
  mounted() {
    this.getCode()
  },
  methods: {
    getCode() {
      getCodeImg('y').then(res => {
        this.codeUrl = "data:image/gif;base64," + res.data.img
        this.$emit('uuid-updated', res.data.uuid)
      })
    }
  }
}
</script>

<style scoped>
.captcha {
  display: flex;
}

.captcha>.code {
  flex: 1;
}

.captcha>.code-img {
  flex: 0 0 120px;
  width: 120px;
  padding-left: 20px;
}
</style>